import axios from 'axios';
import { apiPath } from 'config/constants';
import store from 'store';
import { removePreference, setPreference } from 'helpers/preferences';
import { dbKeys } from 'config/constants';
import { removeToken as removeTokenAction } from '../store/actions/session';
import { refreshToken } from './session';
import { setJWTToken } from 'store/actions/session';

const getAccessToken = async () => {
  const { session } = store.getState();
  const token = session.access;
  if (token) {
    return token;
  } else {
    return null;
  }
};
const instance = axios.create({
  baseURL: apiPath,
  headers: {
    Accept: 'application/json',
  },
});
const removeToken = async () => {
  store.dispatch(removeTokenAction());
  await removePreference(dbKeys.jwt);
};
instance.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();
    if (accessToken && config.headers) {
      let headers: any = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      // config.headers['Authorization'] = `JWT ${jwt}`;
      config.headers = headers;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig?.url !== '/get_token' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        await removeToken();
        try {
          const { session } = store.getState();
          const { data } = await refreshToken(session.refresh);
          const jwt = {
            access: data.access,
            refresh: data.refresh,
          };
          await setPreference(dbKeys.jwt, jwt);
          // Execute setToken store action to update the state
          store.dispatch(setJWTToken(jwt));
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);
export default instance;
