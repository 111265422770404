import { useEffect, useState } from 'react';
import { IonContent, IonModal, useIonLoading, useIonToast } from '@ionic/react';
import {
  initializeFirebaseApp,
  removeRecaptcha,
  renderRecaptcha,
  sendPhoneNumber as sendPhoneNumberUtil,
  // signInWithPhoneNumber,
} from 'helpers/smsAuth';
import { useAppDispatch, useAppSelector } from 'hooks';
import AcceptPrivacyPolicy from './AcceptPrivacyPolicy';
import SendPhoneNumber from './SendPhoneNumber';
import { bannerPhoto, recaptchaSiteKey } from 'config/constants';
import SendVerificationCode, {
  onVerifyPhoneNumberProps,
} from './SendVerificationCode';
import { registerUser } from 'store/actions/session';
import './Login.css';
import { useIntl } from 'react-intl';
import { enableUserNotifications } from 'helpers/notifications';
import messages from 'config/messages';
import Button from 'components/Base/Button/Button';
import WelcomePagesModal from 'components/Elements/WelcomePagesModal/WelcomePagesModal';

const Login: React.FC = () => {
  const steps = {
    sendPhoneNumber: 'send-phone-number',
    sendVerificationCode: 'send-verification-code',
  };
  const [currentStep, setCurrentStep] = useState(steps.sendPhoneNumber);
  const [verificationId, setVerificationId] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [accept, setAccept] = useState<boolean>(false);

  const { data, loading, loaded } = useAppSelector((state) => state.user);
  const { loading: sessionLoading, loaded: sessionLoaded } = useAppSelector(
    (state) => state.session,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    initializeFirebaseApp();
  }, []);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();
  const sendPhoneNumber = () => {
    presentLoading();
    sendPhoneNumberUtil(phoneNumber)
      .then((res) => {
        setVerificationId(res.verificationId);
        setPhoneNumber(res.phoneNumber);
        setCurrentStep(steps.sendVerificationCode);
        dismissLoading();
      })
      .catch(() => {
        dismissLoading();
        presentToast({
          duration: 3000,
          color: 'danger',
          message: intl.formatMessage(messages.anErrorOcurred),
        });
      });
  };

  useEffect(() => {
    // initializeFirebaseApp();
    renderRecaptcha(recaptchaSiteKey);
    // signInWithPhoneNumber();
    return () => {
      removeRecaptcha();
      setCurrentStep(steps.sendPhoneNumber);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps.sendPhoneNumber]);

  const onVerifyPhoneNumber = async (res: onVerifyPhoneNumberProps) => {
    const verifiedAccessToken = res.accessToken;
    if (!sessionLoading && !sessionLoaded) {
      dispatch(
        registerUser({
          name: userName,
          accessToken: verifiedAccessToken,
        }),
      );
    }
  };

  const {
    loading: loadingSession,
    loaded: loadedSession,
    access,
  } = useAppSelector((state) => state.session);

  useEffect(() => {
    if (loadedSession && !loadingSession && access) {
      enableUserNotifications(true);
    }
  }, [loadedSession, loadingSession, access]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    // Logout if session is not valid
    if (!data?.id && !loading && loaded) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setCurrentStep(steps.sendPhoneNumber);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id, loaded, loading]);
  const intl = useIntl();
  return (
    <>
      <IonModal isOpen={isOpen} className="full-modal" backdropDismiss={false}>
        <WelcomePagesModal />
        <IonContent className="ion-padding login-blue-bg" fullscreen={true}>
          <div className="ion-text-center">
            <img src={bannerPhoto} alt="Mihiluze" />
          </div>

          {currentStep === steps.sendPhoneNumber && (
            <>
              <h2 className="ion-no-margin">
                {intl.formatMessage(messages.welcome)}
              </h2>
              <p>{intl.formatMessage(messages.signUpMessage)}</p>
              <SendPhoneNumber
                onChange={({ userName, phoneNumber }) => {
                  setUserName(userName);
                  setPhoneNumber(phoneNumber);
                }}
              />
              <br />
              <AcceptPrivacyPolicy onChange={(ev) => setAccept(ev.accepted)} />
              <br />
              <Button
                onClick={sendPhoneNumber}
                disabled={
                  accept === false || userName === '' || phoneNumber === ''
                }
                title={intl.formatMessage(messages.signUp)}
              />
            </>
          )}
          {currentStep === steps.sendVerificationCode && (
            <SendVerificationCode
              verificationId={verificationId}
              onVerifyPhoneNumber={onVerifyPhoneNumber}
            />
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default Login;
